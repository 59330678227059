// see https://stackoverflow.com/questions/67989175/how-do-i-load-the-bootstrap-5-jquery-plugins-in-an-es6-project-using-webpack
import $ from "expose-loader?exposes=$,jQuery!jquery";
window.jQuery = $;
import 'babel-polyfill';
import 'jquery-ui';
import 'jquery.scrollto';
import { Tooltip, Modal } from 'bootstrap';
import Parsley from 'parsleyjs';
import ClipboardJS from "clipboard";
import { fixTocHighlightedItem, headingsInViewport, initDistance, initEuipoApi, initEuipoDistance, initTranco, initWhoisLookups, positionToc, whoisResultLayout } from "./functions";
import '../scss/main.scss';
import { positionSearchForm } from "./search-form";
$(document).ready(function () {
  $('.material-icons-outlined').css('opacity', '1');

  if (document.body.lang) {
    Parsley.setLocale(document.body.lang);
  }

  $('.parsley').parsley({
    errorsWrapper: '<span class="error-tooltip parsley-errors-list"></span>',
    errorTemplate: '<span></span>',
    animate: false,
    validateIfUnchanged: true,
    errorsContainer: function errorsContainer(el) {
      return el.$element.siblings(".errors");
    }
  }); // initialize bootstrap 5 tooltips

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
  var clipboard = new ClipboardJS('.btn-clipboard');
  clipboard.on('success', function (e) {
    var tooltip = new Tooltip(e.trigger, {
      title: $(e.trigger).data('title')
    });
    tooltip.show();
    window.setTimeout(function () {
      tooltip.dispose();
    }, 1500);
  });
  $("#search-form").submit(function () {
    $("#submit-domain-icon").addClass("d-none");
    $(this).find(".waiting-results").removeClass("d-none");
  });
  $('#submit-domain').mousedown(function (e) {
    if ($('#input-domain').getkeyboard().isVisible()) {
      // to avoid keyboard closing
      e.stopPropagation();
      e.preventDefault();
    }
  });
  $(".language-option").on('click', function () {
    $(this).find(".waiting-redirection").removeClass("d-none");
    $('form#language-form input[name="language"]').val($(this).data('lang'));
    $('form#language-form').submit();
  });
  $("#input-domain").on('focusin', function () {
    if ($('#input-domain').getkeyboard().isVisible()) return;
    $('#section-banner .overlay').addClass('opacity-75');
  });
  $("#input-domain").on('focusout', function () {
    if ($('#input-domain').getkeyboard().isVisible()) return;
    $('#section-banner .overlay').removeClass('opacity-75');
  });
  $(window).blur(function () {
    $('#section-banner .overlay').removeClass('opacity-75');
  });
  fixTocHighlightedItem();
  $("#complaint-form").on('submit', function (e) {
    e.preventDefault();
    var form = $(this);
    var url = form.attr('action');
    $('#complaint-form .error-message').fadeOut();
    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      success: function success() {
        form.trigger('reset');
        var modal = new Modal($('#complaint-thankyou-modal'));
        modal.show();
      },
      error: function error() {
        $('#complaint-form .error-message').fadeIn(500, function () {
          $.scrollTo('#complaint-form .error-message', {
            offset: {
              top: -$('header').outerHeight() - 15
            }
          });
        });
      }
    });
  });
  $('#tld-list button').on('click', function (e) {
    e.preventDefault();
    var $inputDomain = $("#input-domain");
    var tld = $(this).text();
    var inputDomainVal = $inputDomain.val();
    var tldRegex = /\.*(eu|ею|ευ)$/g;

    if (inputDomainVal.match(tldRegex)) {
      $('input[name=domain]').val(inputDomainVal.replace(tldRegex, tld));
    } else {
      $('input[name=domain]').val(inputDomainVal.replace(/\.*$/, '') + tld);
    }
  });
  $(document).on('change', "#select-distance-tld", function (e) {
    e.preventDefault();
    $('.distances-per-tld').one('hidden.bs.collapse', function () {
      var selector = $(this).val();
      $(selector).collapse('show');
    });
    $('.distances-per-tld').collapse('hide');
  });
  $('.key-modal-handler').on('click', function () {
    $('#key-placeholder').val($(this).attr("data-key"));
  });
  $('#distance-modal form').submit(function (e) {
    e.preventDefault();

    if ($(this).parsley().isValid()) {
      var form_method = $(this).attr('method');
      var form_url = $(this).attr('action');
      var form_data = $('#distance-modal form').serialize();
      $('#distance-modal .content-inner').html('<div class="text-center pad-top pad-btm" width="100%"><i class="fa-solid fa-spin fa-spinner fa-3x"></i></div>');
      $.ajax({
        url: form_url,
        type: form_method,
        data: form_data,
        success: function success(response) {
          $('#distance-modal .content-inner').html('<div id="display-distance-fade" style="display: none;">' + response + '</div>');
          $('#distance-modal .content-inner #display-distance-fade').fadeIn();
        },
        error: function error() {
          $('#distance-modal .content-inner').html("<div id=\"display-distance-fade\" ><div class=\"alert alert-danger text-center\">".concat(window.parsleyTranslations.unknownError, "</div></div>"));
        }
      });
    }
  });
  $(window).on("scroll", function () {
    headingsInViewport();
    fixTocHighlightedItem();
    positionSearchForm();
    positionToc();
  });
  $(window).on("resize", function () {
    positionSearchForm();
    positionToc();
  });

  if (document.getElementById('urls-data')) {
    var _urls$language;

    var urls = JSON.parse(document.getElementById('urls-data').textContent.replace(/\r?\n|\r/g, ''));
    var languageCode = (_urls$language = urls === null || urls === void 0 ? void 0 : urls.language) !== null && _urls$language !== void 0 ? _urls$language : "en";

    if (typeof urls.tranco != 'undefined' && urls.tranco !== null) {
      initTranco(urls.tranco);
    } else {
      $('#card-tranco').slideUp();
    }

    if (typeof urls.whoisLookups != 'undefined' && urls.whoisLookups !== null) {
      initWhoisLookups(urls.whoisLookups, languageCode, urls === null || urls === void 0 ? void 0 : urls.ROW);
    } else {
      $('#card-whois-lookups').slideUp();
    }

    if (typeof urls.distance != 'undefined' && urls.distance !== null) {
      initDistance(urls.distance);
    } else {
      $('#card-distance').slideUp();
    }

    if (typeof urls.euipoDistance != 'undefined' && urls.euipoDistance !== null) {
      initEuipoDistance(urls.euipoDistance);
    }

    if (typeof urls.euipoApi != 'undefined' && urls.euipoApi !== null) {
      initEuipoApi(urls.euipoApi);
    }
  } else {
    $('#card-tranco').slideUp();
    $('#card-whois-lookups').slideUp();
    $('#card-distance').slideUp();
  }

  $('.overlay').removeClass('opacity-100');
  whoisResultLayout();
  positionToc();

  if (!$('#emergency-auth #id_email_address_select_2').is(':checked')) {
    $('#emergency-auth #div_id_email_address').hide();
    $('#emergency-auth #div_id_email_address input').removeAttr('data-parsley-required');
  } else {
    $('#emergency-auth #div_id_email_address').attr('data-parsley-required', true);
  }

  $('#emergency-auth #id_email_address_select_2').on('click', function () {
    if ($('#emergency-auth #id_email_address_select_2').is(':checked')) {
      $('#emergency-auth #div_id_email_address').fadeIn();
      $('#emergency-auth #div_id_email_address input').attr('data-parsley-required', true);
    }
  });
  $('#emergency-auth #id_email_address_select_1').on('click', function () {
    if ($('#emergency-auth #id_email_address_select_1').is(':checked')) {
      $('#emergency-auth #div_id_email_address').fadeOut();
      $('#emergency-auth #div_id_email_address input').removeAttr('data-parsley-required');
    }
  });

  if ($('#emergency-auth #error_id_email_address_1').length) {
    $('#emergency-auth #div_id_email_address').fadeIn();
    $('#emergency-auth #div_id_email_address input').attr('data-parsley-required', true);
  }
});